// default reset
@import 'css_reset';

// MOGBOARD THEME
:root {
  --color1: #5723a2;
  --color2: #7a42c9;
  --color3: #e6e6e6;
  --color4: #c1c1c1;
  --color5: #252830;
  --color6: #717988;
  --color7: #1c1c1c;
  --color8: #a7caff;
  --color9: #798ebb;
  --color10: #c2ccff;

  --highlight: #fffca6;
  --highlight2x: #b6b476;
  --highlight3x: #feffc8;

  --default: #434857;
  --default-2x: #4f5463;
  --default-3x: #333a41;
  --red: #e64759;
  --red-2x: #8c2429;
  --red-3x: #ee6b75;
  --blue: #007bff;
  --blue-2x: #005cb8;
  --blue-3x: #58b4ff;
  --green: #1aa571;
  --green-2x: #1a8255;
  --green-3x: #2cdd8f;
  --green-4x: #84fbbe;
  --purple: rgb(134, 162, 255);
  --purple-2x: #5a81b4;
  --purple-3x: #9ab9fa;
  --white: #fff;
  --white-2x: #fff;

  --general-border-radius: 6px;
  --default-outline-size: 1px;
  --table-color: #434857;
  --table-color-2x: #393e4a;
  --table-color-3x: #666d81;
  --table-color-4x: #232b30;
  --table-color-dark: #282b34;
  --search-active: #434857;
  --search-complete: #666d81;
  --dark-blue: #363c4a;
  --dark-blue2: #232631;
  --form-field-height: 38px;
  --form-field-height2: 48px;
  --transition-speed: 100ms;
}

// media query library
$mq-breakpoints: (
  mobile: 320px,
  tablet: 640px,
  desktop: 980px,
  wide: 980px,
);
@import 'mq';

// theme design
@import 'temp';

// tooltip
@import 'tooltip';

// progress bar
@import 'progress-bar';

// custom theme
@import 'theme_modals';
@import 'theme_styles';
@import 'theme_left_nav';
@import 'theme_highcharts';
@import 'pages';
@import 'page_home';
@import 'page_items';
@import 'page_account';
@import 'page_list';
@import 'page_retainer';