.page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  background-color: var(--dark-blue);
  border-radius: 5px;
  box-shadow: 0 1px 3px #000;
  overflow: hidden;

  h1 {
    font-size: 30px;
    font-weight: 200;
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    border-bottom: solid 1px #000;
    box-shadow: 0 1px 0 #444;
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    text-shadow: none;
    margin: 0 0 15px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 300;
    text-shadow: none;
    margin: 0 0 15px 0;
  }

  p {
    line-height: 22px;
  }

  .page-block {
    padding: 20px;
    border-bottom: solid 1px #000;
    box-shadow: 0 1px 0 #444;
  }

  .page-short {
    padding: 0 10%;

    @include mq($until: tablet) {
      padding: 0 15px;
    }
  }

  .page-note {
    text-align: center;
    opacity: 0.6;
    padding: 8px;
  }

  .page-banner {
    background-color: rgba(0, 0, 0, 0.4);
    text-align: center;
    padding: 30px 0;

    border-bottom: solid 1px #000;
    border-top: solid 1px #000;
    box-shadow: 0 1px 0 #444;

    img {
      max-width: 90%;
    }
  }
}

//
// Server Status
//
.server-status-pip {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 3px;
  margin: 5px;

  span {
    display: inline-block;
    float: right;
    font-size: 13px;
    color: var(--red);
    text-shadow: none;
    margin-top: 3px;
  }

  &.server-status-online {
    border-left: solid 10px var(--green-3x);
  }

  &.server-status-offline {
    border-left: solid 10px var(--red);
    color: #666;
  }
}

//
// About
//
.mogboard-header {
  padding: 120px 0;
  text-align: center;
  border-bottom: solid 1px #000;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(255, 255, 255, 0.15);

  position: relative;
  z-index: 2;

  background-image: url('/i/bg/bg_light_dof.webp');
  background-size: cover;
  background-position: center center;
}

.mog-avatar {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}

.mog-credits {
  margin-left: 20px;
}

.mog-honorable {
  font-size: 15px;
  line-height: 22px;

  @include mq($until: tablet) {
    display: none;
  }

  em {
    color: var(--blue-3x);
    font-style: normal;
  }

  small {
    font-size: 13px;
  }
}

//
// Contribute
//

.btn-download {
  width: 200px;
  height: 45px;
}

//
// Mog News
//
.mogboard-news {
  padding: 60px 0;
  text-align: center;
  border-bottom: solid 1px #000;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15), inset 0 0 0 1px rgba(255, 255, 255, 0.15);

  position: relative;
  z-index: 2;

  background-image: url('/i/bg/news.png');
  background-size: cover;

  h6 {
    color: #fff;
    font-size: 52px;
    font-weight: 800;
    text-shadow: 0 1px 20px rgba(0, 0, 0, 1);
  }
}

.mog-news {
  padding: 50px;

  h3 {
    color: var(--highlight);
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  small {
    text-shadow: none;
    display: block;
    margin: 0 0 30px 0;
    color: var(--color6);
  }

  .mog-news-avatar {
    height: 100px;
    width: 100px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  }

  .mog-news-post {
    background-color: #222;
    margin-left: 20px;
    padding: 20px;
    border-radius: 6px;
  }

  @include mq($until: tablet) {
    padding: 10px;

    .flex {
      display: block;
    }

    .flex_10 {
      text-align: center;
      margin-bottom: 15px;
    }

    .flex_10,
    .flex_90 {
      flex: none;
    }

    .mog-news-post {
      padding-left: 0;
    }
  }

  .mog-news-img {
    max-width: 800px;
    border-radius: 5px;
    margin: 10px 0 15px 0;
    box-shadow: 0 1px 3px #000;
  }

  .mog-note {
    border-left: solid 3px #222;
    padding-left: 15px;
    font-size: 13px;
    margin: 5px 0 15px 0;
    opacity: 0.5;
  }
}

.news-ulz {
  line-height: 22px;

  li {
    strong {
      color: var(--highlight);
      display: block;
      margin-bottom: 5px;
    }

    margin-bottom: 20px;
  }
}

.news-img {
  width: 100%;
  height: 500px;
  margin: 10px auto;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: solid 1px #000;
  box-shadow: 0 0 3px #555;
  background-color: #000;

  img {
    transition: 150ms;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.02);
  }

  &:hover {
    img {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.news-note {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 5px;
  opacity: 0.6;

  * {
    font-size: 14px;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
  }

  ul,
  ol {
    margin-bottom: 0;
  }
}

.news-ty {
  background-color: #5c169c;
  background-image: url('/i/bg/bg1.png');
  background-size: cover;
  font-size: 24px;
  letter-spacing: 10px;
  font-weight: 500;
  padding: 25px 10px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 4px;
}

.news-update {
  background-color: #333;
  background-size: cover;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;

  small {
    font-weight: bold;
    color: var(--color8);
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }

  > div {
    line-height: 22px;
    border: solid 1px #555;
    border-left: solid 3px #555;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
}

.the-big-news-post {
  background-color: #375bd1;
  background-image: url('/i/bg/bg1.webp');
  background-size: cover;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 25px 10px;
  text-align: center;

  h4 {
    font-size: 22px;
    font-weight: 500;
  }

  > p {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  a {
    font-weight: bold;
    font-size: 17px;
    color: #adceff;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 8px 14px;
    border-radius: 3px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
}

.patreon-news {
  position: relative;
  background-color: #1976ad;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.25);
  padding: 30px 0;
  margin: 0 -20px;
  overflow: hidden;

  h3 {
    font-size: 22px;
    text-transform: uppercase;
  }

  > div {
    margin-left: 220px;
    padding-right: 30px;
  }

  > img:first-of-type {
    position: absolute;
    bottom: -100px;
    left: -80px;
    height: 450px;
  }

  a {
    color: var(--highlight);
    background-color: rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    border-radius: 3px;
  }
}

//
// Feedback
//
.page-feedback-block {
  padding: 0 50px;
}

.feedback-rules {
  padding-right: 30px;
}

//
// Patreon
//
.page-patron-box {
  background-color: var(--color7);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;

  h5 {
    color: var(--green-3x);
    display: inline-block;
    font-size: 20px;
    margin: 0 25px 0 0;
  }

  .pat-row {
    padding-bottom: 15px;
  }

  .pat-avatar {
    border-radius: 8px;
    box-shadow: 0 1px 3px #000;
    height: 46px;
  }

  .pat_info {
    padding: 14px 0 0 15px;
  }
}
