//
// Modal Styles
//
.modal_cover {
  background-color: rgba(15, 15, 15, 0.8);
  position: fixed;
  z-index: 9500;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: none;

  &.open {
    display: block;
  }
}

.modal {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  background-color: var(--table-color-dark);
  border-radius: 6px;
  box-shadow: 0 2px 5px 1px #000;
  border: solid 1px #000;
  overflow: hidden;
  display: none;

  @include mq($until: desktop) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    transform: translate(0, 0);
  }

  .modal_close_button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: transparent;
    border: none;
    color: #aaa;
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    padding: 0;
    cursor: pointer;
    outline: none;
    z-index: 50;

    &:hover {
      color: #fff;
    }

    i {
      vertical-align: middle;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
  }

  .table {
    background-color: var(--color5);
    box-shadow: none;

    span {
      color: var(--purple-3x);
    }
  }

  &.open {
    display: block;
  }

  label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #fff;
  }

  p {
    display: block;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 28px;
    font-weight: 300;
  }
  h2 {
    font-size: 22px;
    font-weight: 300;
  }
  h3 {
    font-size: 18px;
    font-weight: 300;
  }

  strong {
    color: var(--color8);
  }

  small {
    font-size: 14px;
  }

  ul {
    li {
      line-height: 24px;
    }
  }

  .row {
    padding: 20px;
    text-shadow: none;

    &.row_top {
      border-bottom: solid 1px #000;
    }

    &.row-alert {
      background-color: rgba(75, 0, 20, 0.5);
    }
  }

  .modal_row {
    padding: 20px;
    border-bottom: solid 1px #000;

    &.modal_end {
      border-bottom: none;
    }

    .highlight {
      color: var(--highlight);
    }

    .modal_form_row_1,
    .modal_form_row_2,
    .modal_form_row_3,
    .modal_form_row_4,
    .modal_form_row_5 {
      margin-bottom: 20px;
    }

    .modal_form_row_1 {
    }

    .modal_form_row_2 {
      display: flex;

      > div {
        flex: 0 1 50%;

        &:first-of-type {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-left: 10px;
        }
      }
    }

    .modal_form_end {
      text-align: right;
    }
  }
}

.popup {
  background-color: rgba(15, 15, 15, 0.92);
  position: fixed;
  z-index: 15000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: none;

  &.open {
    display: block;
  }
}
.popup_display {
  position: fixed;
  z-index: 15002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 720px;
  background-color: var(--table-color-dark);
  border-radius: 6px;
  box-shadow: 0 2px 5px 1px #000;
  border: solid 1px #000;
  overflow: hidden;
  text-align: center;
  padding: 30px;

  .popup_close_button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: transparent;
    border: none;
    color: #aaa;
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    padding: 0;
    cursor: pointer;
    outline: none;

    &:hover {
      color: #fff;
    }

    i {
      vertical-align: middle;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
  }

  .popup_icon {
    margin-bottom: 20px;

    i {
      display: inline-block;
      font-size: 42px;
      height: 42px;
      width: 42px;

      position: relative;
      animation-name: popup_icon_loop;
      animation-duration: 1600ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes popup_icon_loop {
        0% {
          transform: scale(1);
        }
        70% {
          transform: scale(1);
        }
        78% {
          transform: scale(1.2);
        }
        86% {
          transform: scale(1);
        }
        94% {
          transform: scale(1.4);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &[data-type='success'] {
      color: var(--green-3x);
    }

    &[data-type='error'] {
      color: var(--red);
    }

    &[data-type='warning'] {
      color: var(--purple);
    }

    &[data-type='info'] {
      color: var(--blue);
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: none;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    padding: 0 30px;
  }
}
