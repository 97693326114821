.highchart {
  text-shadow: none;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 2px #000;
  margin-bottom: 20px;

  > div {
    height: 320px;
  }
}

.highchart-noborder {
  text-shadow: none;
  overflow: hidden;
  margin-bottom: 20px;

  > div {
    height: 320px;
  }
}
