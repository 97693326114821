//
// Main account bits
//
.account {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  @include mq($until: tablet) {
    display: block;
  }

  > div:nth-of-type(1) {
    flex: 0 1 220px;
    background-color: transparent;
    border-right: solid 1px #000;
    padding-top: 30px;

    @include mq($until: tablet) {
      flex: none;
      width: 100%;
      border-right: none;
      border-bottom: solid 1px #000;
    }

    a {
      display: block;
      padding: 15px;
      color: #fff;
      margin-right: -1px;

      &:hover {
        font-weight: 600;
        text-decoration: underline;
      }

      &.active {
        position: relative;
        z-index: 2;
        background-color: #2b2b2b;
        box-shadow: -1px 0 1px #000;
        border-radius: 5px 0 0 5px;
      }
    }
  }

  > div:nth-of-type(2) {
    border-radius: 5px;
    background-color: #2b2b2b;
    box-shadow: 0 1px 3px #000;
    flex: 0 1 calc(100% - 220px);
    padding: 30px;

    @include mq($until: tablet) {
      flex: none;
      width: 100%;
      padding: 10px;
    }
  }

  h1 {
    @include mq($until: tablet) {
      font-size: 24px;
    }
  }

  h5 {
    margin-bottom: 30px;

    @include mq($until: tablet) {
      margin-bottom: 5px;
      font-size: 20px;
    }
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 400;
    margin-top: 6px;
    letter-spacing: 1px;
  }

  .user_avatar {
    height: 64px;
    width: 64px;
    border-radius: 5px;
    box-shadow: 0 1px 5px #000;
  }

  .check_patreon_status {
    display: inline-block;
    background-color: var(--blue);
    border: none;
    line-height: 34px;
    height: 32px;
    border-radius: 3px;
    padding: 0 12px;
    color: #fff;
    cursor: pointer;
  }
}

.pat-perk-title {
  font-size: 20px;
  font-weight: 500;
  margin: -15px -15px 10px -15px;
  padding: 20px;
  text-align: center;
  border-bottom: solid 2px #2b2b2b;
}

.account-panel {
  background-color: var(--dark-blue2);
  box-shadow: 0 1px 3px #000;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;

  > div {
    padding: 15px;
    border-bottom: solid 1px #000;
  }

  .account-none {
    padding: 10px;
  }
}

.fbc {
  > div {
    background-color: #222;
    border-radius: 3px;
    margin-bottom: 3px;
    display: flex;
    transition: 150ms;

    &:hover {
      background-color: #181818;
    }

    > div:nth-of-type(1) {
      flex: 0 1 42px;

      img {
        width: 42px;
        display: grid;
        border-radius: 3px;
      }
    }

    > div:nth-of-type(2) {
      flex: 0 1 calc(100% - 42px - 300px);
      font-size: 18px;
      padding: 12px 0 0 15px;
    }

    > div:nth-of-type(3) {
      flex: 0 1 300px;
      padding: 8px 8px 0 0;
      text-align: right;

      p {
        opacity: 0.4;
        padding: 3px 2px 0 0;
        margin: 0;
      }

      button {
        width: 100%;
        max-width: 100px;
      }
    }
  }
}

//
// Characters
// Retainers
//
.characters,
.retainers {
  h2 {
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
  }

  h3 {
    color: var(--color8);
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 400;
    margin-top: 6px;
    letter-spacing: 1px;
    position: relative;
  }

  h4 {
    color: var(--highlight);
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  h5 {
    margin-bottom: 5px;
    position: relative;
  }

  h6 {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .character_auth_code {
    background-color: #111;
    border-radius: 50px;
    padding: 10px 14px;
    font-size: 18px;
    color: var(--color8);
    position: absolute;
    right: 0;
    font-family: 'Roboto Mono', monospace;
  }

  .character_add_instructions {
    font-size: 14px;
    line-height: 20px;
  }

  .character_add_response {
    text-align: center;
    padding: 15px;
    background-color: #111;
  }

  .character_avatar {
    border-radius: 5px;
    box-shadow: 0 1px 5px #000;
    display: inherit;
    height: 72px;
    width: 72px;
  }

  .char_name {
    font-size: 20px;
  }

  .character_info {
    padding: 0 0 0 15px;
  }
}

.retainer_coin {
  height: 50px;
  width: 50px;
  border-radius: 50px;

  &.unconfirmed {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}

.ret_name {
  display: inline-block;
  margin-top: 6px;
}

.ret_verified {
  display: inline-block;
  margin-left: 15px;
  color: var(--green-4x);

  i {
    display: inline-table;
    vertical-align: middle;
  }
}

.retainer_verify {
  margin-top: 10px;
  padding: 15px;
  background-color: #111;
  border-radius: 5px;

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .alert-blue {
    margin-bottom: 0;
  }
}

.retainer_confirm {
  background-color: var(--blue-2x);
  color: #fff;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 1px 4px #000;
  font-size: 18px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--blue);
  }
}

.retainer_instructions {
  font-size: 19px;
}

.retainer_item_search_response {
  margin: 10px 0;
  border-radius: 5px;
  overflow: hidden;
  background-color: #222;

  .item_button {
    display: block;
    background-color: #ddd;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #aaa;
    box-shadow: none;

    padding: 0 12px;
    height: 35px;
    line-height: 35px;
    color: #333;
    width: 100%;
    text-shadow: none;
    text-align: left;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background-color: var(--blue);
      border-bottom: solid 1px var(--blue);
      color: #fff;
    }
  }

  p {
    padding: 15px;
  }
}

.retainer_how_does_it_work {
  margin-top: 10px;

  p,
  strong {
    font-size: 13px;
    line-height: 22px;
  }
}

.retainer-gilcheck {
  border: solid 1px var(--highlight);
  border-radius: 4px;
  padding: 6px;
  display: inline-block;
  background-color: transparent;
  color: var(--highlight);
  font-size: 17px;
  margin: 0 5px;
  width: 120px;
  outline: none;
  text-align: center;

  &:hover {
    background-color: var(--dark-blue2);
  }
}

//
// Alerts
//
.alerts {
  > small {
    opacity: 0.5;
    font-size: 13px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    padding: 8px;

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h6 {
    text-shadow: none;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color6);
    margin: 0 0 2px 0;
  }

  code.box {
    margin: 0;
  }

  .alert_info_box {
    margin-bottom: 30px;

    td {
      color: #fff;
    }
  }

  .alert_icon {
    border-radius: 5px;
    box-shadow: 0 1px 5px #000;
  }
  .alert_item_level {
    display: inline-block;
    margin-right: 10px;
    color: var(--color8);
  }

  .alert_item {
    font-size: 20px;
  }

  .alert_events {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    line-height: 16px;
    padding-top: 4px;
    color: var(--table-color-3x);
  }
}

//
// Lists
//
.lists {
  h3 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    padding: 8px;
  }

  .list-entry-header {
    cursor: pointer;
  }

  .list-entry {
    display: none;
  }

  .open {
    display: block;
  }

  ul {
    list-style: none;
    margin: 10px 0;

    li {
      margin-bottom: 10px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      padding: 0 15px 0 0;
      overflow: hidden;

      &:hover {
        background-color: rgba(20, 20, 20, 0.3);
      }

      span {
        margin-left: 60px;
      }

      small {
        float: right;
      }

      .list-item {
        width: 36px;
        height: 36px;
        border-radius: 6px;
        vertical-align: middle;
        margin-right: 20px;
        position: absolute;
      }
    }
  }
}

.delete-list-block {
  white-space: nowrap;
  padding: 0 15px 15px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  opacity: 0.5;
  transition: 100ms;

  &:hover {
    opacity: 1;
  }
}
