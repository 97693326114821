.pl {
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @include mq($until: tablet) {
    padding: 5px;
  }

  h1 {
    font-size: 30px;
    margin: 10px 0 30px 0;

    span {
      font-size: 15px;
      display: inline-block;
      margin-left: 10px;
    }
  }

  h2 {
    font-size: 24px;
    margin: 0 0 10px 0;

    span {
      font-size: 15px;
      display: inline-block;
      margin-left: 10px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: none;
    margin: 0;
    padding: 0 0 15px 0;
  }

  .pl_i {
    display: flex;
    margin-bottom: 30px;

    > div:first-of-type {
      flex: 0 1 100px;

      img {
        height: 100px;
        border-radius: 5px;
        box-shadow: 0 1px 3px #000;
      }
    }

    > div:last-of-type {
      flex: 0 1 calc(100% - 120px);
      padding-left: 20px;
    }
  }

  .pc_i {
    display: flex;

    > div:first-of-type {
      flex: 0 1 250px;

      .pc_port {
        width: 250px;
        height: 540px;
        display: block;
        position: relative;
        border-radius: 5px;
        box-shadow: 0 1px 3px #000;
        overflow: hidden;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 600px;
        }
      }
    }

    > div:last-of-type {
      flex: 0 1 calc(100% - 250px);
      padding-left: 20px;
    }
  }

  .pl_mt_p {
    padding-right: 10px;
  }

  .pl_mt_h {
    padding-left: 10px;
  }

  .pl_remove {
    float: right;
    color: var(--red-2x);
  }

  .pl-mt small,
  .pc_i small {
    display: block;
    margin-top: 10px;
    text-align: right;
    font-size: 13px;

    @include mq($until: tablet) {
      text-align: justify;
      line-height: 18px;
    }
  }

  .pl_you {
    margin-bottom: 20px;
  }

  .pc_stats {
    margin-bottom: 30px;
    background-color: #333;
    box-shadow: 0 1px 3px #000;
    border-radius: 3px;
    overflow: hidden;

    .pc_stats2 {
      background-color: #222;
      border-top: solid 1px #000;
    }

    h6 {
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 0 10px 0;
      display: block;
    }

    .pc_stat {
      text-align: center;
      font-size: 20px;
      padding: 20px 10px;

      img {
        margin: 0 5px 0 0;
        height: 24px;
        vertical-align: middle;
      }

      span {
        color: var(--highlight);
      }
    }

    .pc_stat2 {
      text-align: center;
      padding: 20px 10px;

      img {
        height: 42px;
        border-radius: 10px;
        box-shadow: 0 1px 3px #000;
      }

      div {
        margin-top: 8px;
      }
    }
  }

  @include mq($until: tablet) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;

      span {
        display: block;
        margin-left: 0;
        padding: 2px 0;
        font-size: 13px;
      }
    }

    .pl_i,
    .pc_i {
      display: block;

      > div:first-of-type {
        display: none;
      }

      > div:last-of-type {
        flex: none;
        padding-left: 0;
      }
    }
    .flex {
      display: block;

      .pl_mt_p {
        margin-bottom: 20px;
      }

      .pl_mt_p,
      .pl_mt_h {
        flex: none;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.page-footer {
  padding: 30px;

  h4 {
    font-size: 20px;
  }
}
