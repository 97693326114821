//
// Styles JUST for the theme page.
//

.theme {
  padding: 50px;

  .theme-logo {
    padding: 30px;
  }

  .theme-text {
    max-width: 800px;
  }

  .theme-box {
    padding: 30px;
  }
}
