.home {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  @include mq($until: desktop) {
    display: block;
  }

  > div:nth-of-type(1) {
    flex: 0 1 240px;

    @include mq($until: desktop) {
      flex: 0 1 100%;
    }

    h3 {
      font-size: 20px;

      .list-icon {
        display: inline-table;
        vertical-align: middle;
        margin-right: 12px;
      }
    }

    p {
      line-height: 22px;
      text-align: justify;
    }

    strong {
      text-align: left;
      display: block;
      margin: 0 0 10px 0;
    }

    div {
      background-color: var(--dark-blue);
      box-shadow: 0 1px 3px #000;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 25px;
    }

    button,
    a {
      background-color: transparent;
      border: none;
      border-bottom: solid 1px #000;
      font-size: 15px;
      padding: 10px;
      text-align: left;
      display: block;
      width: 100%;
      outline: none;
      cursor: pointer;
      color: #fff;

      &:hover {
        background-color: var(--default-2x);
      }

      img {
        display: inline-table;
        height: 18px;
        border-radius: 40px;
        vertical-align: middle;
        margin: -3px 5px 0 0;
      }
    }
  }

  > div:nth-of-type(2) {
    flex: 0 1 calc(100% - 240px - 360px);
    padding: 0 20px;

    @include mq($until: desktop) {
      flex: 0 1 100%;
      padding: 0;
    }

    .home-tab {
      display: none;

      &.open {
        display: block;
      }
    }
  }

  > div:nth-of-type(3) {
    flex: 0 1 360px;

    @include mq($until: desktop) {
      flex: 0 1 100%;
    }

    h4 {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-shadow: none;
    }
  }
}

.home-feed {
  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 5px 0;
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #666;
    margin: 0 0 10px 0;

    @include mq($until: desktop) {
      margin-bottom: 3px;
    }
  }

  .hf-row {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: #222;
    padding: 15px;

    &.hf-row-LIST_PRICES {
      border-left: solid 5px var(--blue);

      .hf-list-prices {
        display: flex;

        @include mq($until: desktop) {
          display: block;
        }

        > div:nth-of-type(1) {
          flex: 0 1 60px;

          @include mq($until: desktop) {
            display: none;
          }

          img {
            border-radius: 5px;
            box-shadow: 0 1px 3px #000;
            height: 60px;
          }
        }

        > div:nth-of-type(2) {
          flex: 0 1 calc(40% - 60px);
          padding-left: 15px;

          @include mq($until: desktop) {
            flex: 0 1 100%;
            padding-left: 0;
            margin-bottom: 20px;
          }
        }

        > div:nth-of-type(3) {
          flex: 0 1 calc(60%);

          @include mq($until: desktop) {
            flex: 0 1 100%;
          }
        }
      }
    }

    &.hf-row-ALERT_EVENT {
      border-left: solid 5px var(--green-3x);

      .hf-alert {
        display: flex;

        @include mq($until: desktop) {
          display: block;
        }

        > div:nth-of-type(1) {
          flex: 0 1 60px;

          @include mq($until: desktop) {
            display: none;
          }

          img {
            border-radius: 5px;
            box-shadow: 0 1px 3px #000;
            height: 60px;
          }
        }

        > div:nth-of-type(2) {
          flex: 0 1 calc(100% - 60px);
          padding-left: 15px;

          @include mq($until: desktop) {
            flex: 0 1 100%;
            padding-left: 0;
            margin-bottom: 20px;
          }
        }
      }
    }

    img {
      vertical-align: middle;
      margin: -3px 0 0 0;
    }

    .text-gray {
      font-size: 14px;
    }

    .hf-list-name {
      font-size: 14px;
    }
  }
}

.updates_box {
  background-color: #222;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 1px 3px #000;

  h5 {
    font-size: 14px !important;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 3px 0;
  }

  > div {
    flex: 0 1 auto;
    flex-grow: 1;
    padding: 10px;
    text-align: center;
    font-size: 14px;

    img {
      display: inline-table;
      vertical-align: middle;
      margin: 0 2px 2px 0;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  .price-hq {
    color: #dddc87;
  }
}

.home-guides {
  border-radius: 3px;
  background-color: var(--dark-blue);
  box-shadow: 0 1px 3px #000;
  margin-bottom: 15px;
  padding: 25px 10px;
  text-align: center;

  h4 {
    font-size: 22px;
    font-weight: 500;
  }

  > p {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  a.cta {
    font-weight: bold;
    font-size: 17px;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 8px 14px;
    border-radius: 3px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
}

.home-logged-out {
  border-radius: 5px;
  padding: 150px 30%;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 34px !important;

  background-image: url('/i/bg/homepage.webp');
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);

  @include mq($until: desktop) {
    padding: 30px 15px;
  }

  p {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px !important;
    text-shadow: 0 2px 5px #000 !important;
  }

  a {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgba(57, 82, 194, 0.86);
    color: #fff;
    font-size: 20px;
    box-shadow: 0 1px 3px #000;
  }
}

.home-box,
.home-box2 {
  background-color: var(--dark-blue);
  box-shadow: 0 1px 3px #000;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;

  h3 {
    font-size: 20px;
    font-weight: 400;
  }

  h4 {
    font-size: 22px;
    font-weight: 300;
    color: var(--highlight);
    text-transform: uppercase;
    margin: 0 0 2px 0;
  }

  .home-row {
    border-bottom: solid 1px #000;
    line-height: 26px;

    > div {
      padding: 12px;
    }

    img {
      display: inline-table;
      height: 32px;
    }
  }
}

.home-news {
  background-color: #1e2b1e;
  line-height: 20px;

  strong {
    color: var(--highlight);
    display: block;
    margin-bottom: 8px;
    font-size: 18px;
  }
}

.home-box2 {
  background-color: #222;
  border-left: solid 5px #444;
  padding: 20px;
}

.patreon-discord {
  display: flex;
  overflow: hidden;

  a {
    flex: 0 1 50%;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;

    > span {
      transition: 150ms cubic-bezier(0.4, 0, 1, 1);
      display: inline-block;
      position: relative;
    }

    &:hover > span {
      transform: scale(1.4);
    }

    &.patreon {
      background-color: #f86754;
      border-left: solid 2px #000;
      overflow: hidden;
    }

    &.contrib {
      background-color: #7500b2;
      border-left: solid 2px #000;
      overflow: hidden;
    }

    &.discord {
      background-color: #7289da;
      font-weight: bold;
      text-shadow: none;
      overflow: hidden;
      color: #fff;

      img {
        vertical-align: middle;
        height: 30px;
      }
    }
  }
}

.home-alert-row {
  padding: 15px;
  border-bottom: solid 1px #000;
  display: flex;

  > div:first-of-type {
    flex: 0 1 46px;

    @include mq($until: desktop) {
      display: none;
    }
  }

  > div:last-of-type {
    flex: 0 1 calc(100% - 46px);
    padding-left: 15px;

    @include mq($until: desktop) {
      flex: 0 1 100%;
      padding-left: 0;
    }
  }

  .item_icon {
    height: 46px;
    width: 46px;
    border-radius: 5px;
    box-shadow: 0 1px 3px #000;
  }

  .home-alert-item-info {
    > div:first-of-type {
      font-size: 20px;

      small {
        display: block;
        margin: 5px 0 0 0;
        color: #888;
        font-size: 14px;
      }
    }

    > div:last-of-type {
      h4 {
        font-size: 14px;
        margin: 0 0 15px 0;
      }
    }

    .home-alert-watcher {
      margin: 0 0 15px 0;
    }
  }
}

//
// Home Item Lists
//
.home-itemlist {
  padding: 15px;

  ul {
    padding: 0;
    margin: 10px 0 0 0;
    list-style: none;

    li {
      .item-icon {
        height: 32px;
        vertical-align: middle;
        border-radius: 3px;
        display: inline-table;
        margin: 0 10px 0 0;
        padding-top: 4px;
      }

      small {
        display: inline-block;
        margin-left: 10px;
        font-size: 13px;
        text-shadow: none;
      }

      h2 {
        color: var(--green-3x);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 12px 0;
      }
    }
  }
}

//
// Trending
//
.home-trending {
  > div {
    display: flex;
    border-bottom: solid 1px #000;

    > div:first-of-type {
      flex: 0 1 50px;
      border-right: solid 1px #000;
      overflow: hidden;
      position: relative;
      width: 50px;
      height: 50px;

      .home-trending-item {
        position: absolute;
        width: 55px;
        height: 55px;
        margin-top: -2.5px;
        margin-left: -2.5px;
      }
    }

    > div:last-of-type {
      flex: 0 1 calc(100% - 50px);
      padding-left: 10px;

      > div {
        margin-top: 8px;
      }

      small {
        font-size: 12px;
      }
    }
  }
}

.btn-disabled {
  pointer-events: none;
}
